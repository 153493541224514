import React, { useEffect, useRef, useState } from 'react';
import './Collection.css';
import addIcon from '../../images/modal-plus.png';
import ElementWindow from '../ElementWindow/ElementWindow';
import { useDispatch, useSelector } from 'react-redux';
import { calculation, setElement, setForwardedFromElementWindow } from '../Element/element.slice';
import Component from '../Component/Component';
import Context from '../Context/Context';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

function Collection({ data, parentElement, props, action, property, setElementState }) {
    data = data !== undefined ? data.map((record, index) => record.id === undefined ? { ...record, id: index } : record) : undefined;

    const [isElementShow, setIsElementShow] = useState(false);
    const [elementWindowAction, setElementWindowAction] = useState(action);
    const [elementWindowData, setElementWindowData] = useState();
    const { page } = useSelector(state => state.element);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const submit = (element) => {
        let dataResult = data === undefined ? [] : data;
        if (element?.id === undefined) {
            dataResult = [...dataResult, { ...element, id: Math.random().toString().substring(2) }];
        } else {
            dataResult = dataResult.map(item => {
                if (item.id !== element.id) {
                    return item;
                }
                return element;
            });
        }
        if (setElementState) {
            setElementState(property, dataResult);
            navigate(-1);
            return;
        }
        dispatch(setElement({ property, value: dataResult }));
        dispatch(setForwardedFromElementWindow(true));
        navigate(-1);
    };

    const handleOutsideClick = e => {
        if (Array.from(e.target.parentElement.classList).includes('context-menu')) {
            setTimeout(() => {
                setContextStyles({ display: 'none' });
            }, 200);
            return;
        }
        setContextStyles({ display: 'none' });
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        }
    });

    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
        if (isElementShow) {
            setScrollPosition(document.getElementsByTagName('main')[0].scrollTop);
            document.getElementsByTagName('main')[0].scrollTo(0, 0);
        } else {
            document.getElementsByTagName('main')[0].scrollTo(0, scrollPosition);
        }
    }, [isElementShow]);

    const [contextStyles, setContextStyles] = useState({
        display: 'none',
    });


    const collectionContainerRef = useRef();
    const handleContextMenu = (e, item) => {
        setElementWindowData(item);
        setTimeout(() => {
            const clientX = e.clientX - collectionContainerRef.current.getBoundingClientRect().left;
            const clientY = e.clientY + document.getElementsByTagName('main')[0].scrollTop;
            setContextStyles({
                display: 'block',
                top: document.body.clientHeight - e.clientY > 100 ? clientY : 'auto',
                bottom: document.body.clientHeight - e.clientY > 100 ? 'auto' : '10px',
                left: collectionContainerRef.current.getBoundingClientRect().width - clientX > 200 ? clientX : 'auto',
                right: collectionContainerRef.current.getBoundingClientRect().width - clientX > 200 ? 'auto' : '10px',
            });
        }, 100);
    }

    const contextActions = {
        edit: () => { page.accesses.canEdit && setElementWindowAction('edit'); setIsElementShow(true); },
        delete: () => { (page.accesses.canEdit === true && elementWindowData?.id !== undefined) && setElementState !== undefined ? setElementState(property, data?.filter(item => item?.id !== elementWindowData?.id)) : dispatch(setElement({ property, value: data?.filter(item => item?.id !== elementWindowData?.id) })); },
        copy: () => { page.accesses.canEdit && setElementWindowAction('copy'); setIsElementShow(true); setElementWindowData({ ...elementWindowData, id: undefined }) },
    }

    const location = useLocation();
    const search = location.search;

    const searchParams = search.split('?')[1]?.split('&');
    const cids = searchParams?.map(search => search.split('=')[0]).filter(key => key.startsWith('cid_'));

    useEffect(() => {
        if (isElementShow && cids?.find(cid => cid === 'cid_' + property) === undefined) {
            setIsElementShow(false);
        } else {
            const cid = cids?.find(cid => cid === 'cid_' + property);
            if (isElementShow === false && cid !== undefined) {
                setIsElementShow(true);
                setElementWindowData(data?.find(item => item.id.toString() === searchParams?.find(search => search.split('=')[0] === cid).split('=')[1]));
            }
        }
    }, [cids]);

    return (
        <div ref={collectionContainerRef}>
            <div className='collection'>
                <div className='flex-space-between full-width'>
                    <p className="label-view"><span className='required'>{(props.required && action !== 'view') ? '*' : ''}</span>{props.label}</p>
                    {
                        (action !== 'view') &&
                        <button className='button list-add' onClick={() => { setIsElementShow(true); setElementWindowAction('add'); navigate((search !== '' ? search + '&' : '?') + 'cid_' + property + '=') }}>
                            <img src={addIcon} alt="" width={20} />
                            Добавить
                        </button>
                    }
                </div>
                <div className='collection-body'>
                    {
                        (data !== undefined && data.length > 0) &&
                        data.filter(item => item.id !== undefined).map((item, index) => {
                            const propsReturn = props?.elements?.[props?.listTitle];
                            return (
                                <NavLink to={(search !== '' ? search + '&' : '?') + 'cid_' + property + '=' + item.id} key={index} tabIndex={0} className='collection-item-container flex-column collection-list-item' onClick={() => { setIsElementShow(true); setElementWindowAction(action === 'view' ? 'view' : 'edit'); setElementWindowData(item) }} onContextMenu={e => handleContextMenu(e, item)}>
                                    <div className="min-height full-width">
                                        <Component props={{ ...propsReturn, type: propsReturn?.type === 'link' ? 'text' : propsReturn?.type }} data={propsReturn?.type === 'link' ? item?.[props?.listTitle].text : item?.[props?.listTitle]} action='view' property={props?.listTitle} />
                                    </div>
                                    {
                                        props?.listSubtitles?.map((subtitle, index) => {
                                            const resultProps = { ...props.elements[subtitle] };
                                            if (props.elements[subtitle]?.unit !== undefined) {
                                                if (props.elements[subtitle].unit.type === 'link') {
                                                    if (item[props.elements[subtitle].unit.property] !== undefined) {
                                                        resultProps.unit = item[props.elements[subtitle].unit.property];
                                                    } else if (parentElement[props.elements[subtitle].unit.property] !== undefined) {
                                                        resultProps.unit = parentElement[props.elements[subtitle].unit.property];
                                                    } else {
                                                        delete resultProps.unit;
                                                    }
                                                } else if (props.elements[subtitle].unit.type === 'static') {
                                                    resultProps.unit = props.elements[subtitle].unit.value;
                                                }
                                            }
                                            const returnData = props.elements[subtitle]?.calculation !== undefined ? calculation({ input: props.elements[subtitle].calculation, data: item, parentElement }) : item?.[subtitle];                                            
                                            return (
                                                <div key={index} className='min-height full-width'>
                                                    <Component props={{...resultProps, type: resultProps?.type === 'link' ? 'text' : resultProps?.type}} data={resultProps?.type === 'link' ? returnData?.text : returnData} action='view' property={subtitle} />
                                                </div>
                                            )
                                        }

                                        )
                                    }
                                </NavLink>
                            )
                        }
                        )
                    }
                    {
                        (data === undefined || data.filter(item => item.id !== undefined).length === 0) &&
                        <p className='description center-text'>Пустая коллекция</p>
                    }
                </div>
                {
                    isElementShow &&
                    <ElementWindow onOk={(element) => submit(element)} onCancel={() => { dispatch(setForwardedFromElementWindow(true)); navigate(-1) }} element={elementWindowData} parentElement={parentElement} action={action} elementWindowAction={elementWindowAction} property={property} props={props} />
                }
            </div>
            {
                action !== 'view' &&
                <Context target={contextActions} style={contextStyles} elements={[{ label: 'Скопировать', disabled: !page?.accesses?.canAdd, action: 'copy' }, { label: 'Редактировать', disabled: !(page?.accesses?.canEdit && elementWindowAction?.canEdit !== false), action: 'edit' }, { label: 'Удалить', disabled: !(page?.accesses?.canEdit && elementWindowAction?.canEdit !== false), action: 'delete' }]} />
            }
        </div>
    )
}

export default Collection
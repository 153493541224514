import React from 'react';
import { host } from '../../App';
import './Image.css';

function Image({ props, data }) {
    return (
        <div className='image-view'>
            <p className="label-view">{props.label}</p>
            <img src={host.url + '/api/' + data} alt='' />
        </div>
    )
}

export default Image
import React, { useEffect, useState } from 'react';
import './JSON.css';
import { useDispatch } from 'react-redux';
import { setElement } from '../Element/element.slice';
import { JsonEditor } from 'json-edit-react';

function JSONCOMPONENT({ props, data, action, property, setElementState }) {
    const dispatch = useDispatch();

    const [jsonData, setJsonData] = useState(data ?? {});
    useEffect(() => {
        if (setElementState) {
            setElementState(property, jsonData);
            return;
        }
        dispatch(setElement({ property, value: jsonData }));
    }, [jsonData, dispatch, property, data, setElementState]);

    return (
        <div>
            {
                action === 'view' &&
                <div className='json-view'>
                    <p className="label-view">{props.label}</p>
                    <JsonEditor
                        data={jsonData}
                        rootName='components'
                        theme={[
                            'githubDark',
                            {
                                input: { color: 'var(--primary-color)' }
                            }
                        ]}
                        minWidth={'100%'}
                        restrictAdd={true}
                        restrictEdit={true}
                        restrictDelete={true}
                    />
                </div>
            }
            {
                (action === 'add' || action === 'edit' || action === 'copy') &&
                <div className='json-view'>
                    <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
                    <JsonEditor
                        data={jsonData}
                        setData={setJsonData}
                        rootName='components'
                        theme={[
                            'githubDark',
                            {
                                input: { color: 'var(--primary-color)' }
                            }
                        ]}
                        minWidth={'100%'}
                    />
                </div>
            }
        </div>
    )
}

export default JSONCOMPONENT
import React, { useRef } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

function ChartComponent({ data, props }) {
    const chartContainerRef = useRef(null);
    const { height, width, lineDataKey, xAxisDataKey, yAxisDataKey, CustomTooltip, domain } = props;
    return (
        <div ref={chartContainerRef} className="chart" style={{ height: height ?? '300px', width: width ?? '100%' }}>
            <ResponsiveContainer>
                <LineChart width={chartContainerRef.current?.offsetWidth} height={chartContainerRef.current?.offsetHeight} data={data} margin={{ top: 25, right: 55, bottom: 25, left: 55 }}>
                    <Line type="monotone" dataKey={lineDataKey} stroke="#0076FE" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey={xAxisDataKey ?? ''} />
                    <YAxis dataKey={yAxisDataKey ?? ''} domain={domain ?? undefined} />
                    <Tooltip content={CustomTooltip ? <CustomTooltip /> : null} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default ChartComponent
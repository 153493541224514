import React from 'react'
import { NavLink } from 'react-router-dom';
import ImageComponent from '../ImageComponent/ImageComponent';
import { host } from '../../App';
import { splitValue } from '../Number/Number';
import { setScrollTop } from './list.slice';
import { useDispatch } from 'react-redux';
import folderIcon from '../../images/folder.png';
import './List.css';

function ListElements({ items, list, onContextMenu, hasLink }) {
    const dispatch = useDispatch();

    return (
        <div className="list-items-container">
            {
                items.filter(item => item.isFolder === true).map((item, index) => {
                    return (
                        <div style={{ position: 'relative' }} key={index}>
                            <NavLink to={hasLink ? item.link : '?lid=' + item?._id.toString()} className='list-item list-folder' onClick={() => { dispatch(setScrollTop({ list: list.collection, scrollTop: Number(document.getElementsByTagName('main')[0].scrollTop) })) }}>
                                <div className="list-icon" style={{ position: 'relative' }}>
                                    <ImageComponent src={folderIcon} width={48} />
                                    {
                                        item.icon !== undefined &&
                                        <div className="list-folder-icon">
                                            <ImageComponent src={host.url + '/api/' + item.icon} width={20} />
                                        </div>
                                    }
                                </div>
                                <div className={item.icon === undefined ? "list-info-without-icon" : "list-info"}>
                                    <div className="list-titles">
                                        <p className="list-title">{item.title}</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    )
                })
            }
            {
                items.filter(item => item.isFolder !== true).map((item, index) => {
                    const listTitleProperty = typeof item?.[list?.listTitle] !== 'object' ? item?.[list?.listTitle] : item?.[list?.listTitle]?.text;
                    const listTitleUndefinedProperty = typeof item?.[list?.listTitleIfUndefined] !== 'object' ? item?.[list?.listTitleIfUndefined] : item?.[list?.listTitleIfUndefined]?.text;
                    const listTitle = listTitleProperty !== undefined ? listTitleProperty : listTitleUndefinedProperty !== undefined ? listTitleUndefinedProperty : '';
                    
                    const listSubtitlePropery = typeof item?.[list?.listSubtitle] !== 'object' ? item?.[list?.listSubtitle] : item?.[list?.listSubtitle]?.text;
                    const listSubtitle = listSubtitlePropery !== undefined ? list?.listSubtitle?.includes('.') ? item?.[list?.listSubtitle?.split('.')?.[0]]?.[list?.listSubtitle?.split('.')[1]] : listSubtitlePropery : '';
                    
                    const listDateTime = item?.[list?.listDateTime] !== undefined ? new Date(item[list.listDateTime]).toLocaleString() : '';
                    const listSum = item?.[list?.listSum] !== undefined ? item[list.listSum] : '';
                    const listSumAddition = item?.[list.listSum] !== undefined ? list.listSumAddition : '';
                    return (
                        <div style={{ position: 'relative' }} key={index}>
                            <NavLink to={hasLink ? item.link : '/view/' + list.collection + '/' + item._id?.toString()} className='list-item' onContextMenu={(e) => { onContextMenu !== undefined && onContextMenu(e, list.collection + '/' + item._id.toString(), item) }} onClick={() => { dispatch(setScrollTop({ list: list.collection, scrollTop: Number(document.getElementsByTagName('main')[0].scrollTop) })) }}>
                                {
                                    item.icon !== undefined &&
                                    <div className="list-icon">
                                        <ImageComponent src={host.url + '/api/' + item.icon} width={48} />
                                    </div>
                                }
                                <div className={item.icon === undefined ? "list-info-without-icon" : "list-info"}>
                                    <div className="list-titles">
                                        <p className="list-title">{typeof listTitle !== 'object' ? listTitle : ''}</p>
                                        <p className='list-subtitle'>{typeof listSubtitle !== 'object' ? listSubtitle : ''}</p>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div className="list-overalls">
                                            <p className="list-date-time">{typeof listDateTime !== 'object' ? listDateTime : ''}</p>
                                            <p className="list-sum">{typeof listSum !== 'object' ? typeof listSum === 'number' ? splitValue(listSum) : listSum : ''} {typeof listSumAddition !== 'object' ? listSumAddition : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ListElements
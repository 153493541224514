import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdditionalElements, fetchList, fetchUpdates, setLastUpdateTime, setSearch, setShowedControl, setSort, setStatus, setUpdateItems } from './list.slice';
import './List.css';
import addIcon from '../../images/modal-plus.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Context from '../Context/Context';
import Dialog from '../Dialog/Dialog';
import searchIcon from '../../images/search.png';
import closeIcon from '../../images/close.png';
import { fetchDeleteElement, setElementStatus } from '../Element/element.slice';
//import filterIcon from '../../images/filter.png';
import sortIcon from '../../images/sort.png';
import sortAsc from '../../images/sort-asc.png';
import sortDesc from '../../images/sort-desc.png';
import Modal from '../Modal/Modal';
import ImageComponent from '../ImageComponent/ImageComponent';
import emptyShop from '../../images/shop-gray.png';
import ListElements from './ListElements';
import ShopSwitchter from '../ShopSwitchter/ShopSwitchter';
import { fetchShops, setShop } from '../Shop/shop.slice';
function List({ page }) {
    const { items, status, pageData, pagination } = useSelector(state => state.list);
    const [itemsState, setItemsToState] = useState([]);
    const [propsState, setPropsState] = useState({});

    const setItemsState = useCallback((props = {}) => {
        const search = props?.search ?? propsState?.search ?? '';
        if (props?.search !== undefined) {
            setPropsState({ ...propsState, search: props.search });
        }
        const sorts = props.sorts ?? propsState?.sorts ?? {};
        if (props?.sorts !== undefined) {
            setPropsState({ ...propsState, sorts: props.sorts });
        }
        const filters = props.filters ?? propsState?.filters ?? {};
        if (props?.filters !== undefined) {
            setPropsState({ ...propsState, filters: props.filters });
        }
        let itemsCopy = [...items];
        //filter
        Object.entries(filters).forEach(([key, value]) => {
            itemsCopy = itemsCopy.filter(item => (item[key] ? item[key] : emptyText) === value);
        });
        //search
        if (search !== '') {
            const titles = itemsCopy.filter(item => (item[pageData.listTitle] !== undefined ? item[pageData.listTitle] : item[pageData.listTitleIfUndefined] !== undefined ? item[pageData.listTitleIfUndefined] : emptyText)?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
            const subTitles = itemsCopy.filter(item => (pageData?.listSubtitle?.includes('.') ? item[pageData?.listSubtitle?.split('.')[0]]?.[pageData?.listSubtitle?.split('.')[1]] : item[pageData?.listSubtitle])?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
            const dateTimes = itemsCopy.filter(item => new Date(item[pageData.listDateTime])?.toLocaleString()?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
            const sums = itemsCopy.filter(item => item[pageData.listSum]?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
            const titlesIncludes = itemsCopy.filter(item => (item[pageData.listTitle] !== undefined ? item[pageData.listTitle] : item[pageData.listTitleIfUndefined] !== undefined ? item[pageData.listTitleIfUndefined] : emptyText)?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()));
            const subTitlesIncludes = itemsCopy.filter(item => (pageData?.listSubtitle?.includes('.') ? item[pageData?.listSubtitle?.split('.')[0]]?.[pageData?.listSubtitle?.split('.')[1]] : item[pageData?.listSubtitle])?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()));
            const dateTimesIncludes = itemsCopy.filter(item => new Date(item[pageData.listDateTime])?.toLocaleString()?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()));
            const sumsIncludes = itemsCopy.filter(item => item[pageData.listSum]?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()));
            itemsCopy = titles.concat(subTitles, dateTimes, sums, titlesIncludes, subTitlesIncludes, dateTimesIncludes, sumsIncludes).filter((item, index, self) => self.indexOf(item) === index);
        }
        //sort
        Object.entries(sorts).forEach(([key, value]) => {
            itemsCopy = itemsCopy.sort((a, b) => {
                if (a[key] < b[key] || (typeof a[key] === 'string' && a[key].localeCompare(b[key]) === -1)) {
                    return value === '1' ? -1 : value === '-1' ? 1 : 0;
                }
                if (a[key] > b[key] || (typeof a[key] === 'string' && a[key].localeCompare(b[key]) === 1)) {
                    return value === '1' ? 1 : value === '-1' ? -1 : 0;
                }
                return 0;
            });
        });

        setItemsToState(itemsCopy);
    }, [items, pageData, propsState, setItemsToState]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (status === 'success' && page?.title === pageData?.title) {
            setItemsToState(items);
            setItemsState(propsState);
        }
    }, [items, status]);

    const elementStatus = useSelector(state => state.element.status);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const [contextStyles, setContextStyles] = useState({
        display: 'none',
    });
    const listContainerRef = useRef(null);
    const [target, setTarget] = useState(undefined);
    const [element, setElement] = useState(undefined);

    const location = useLocation();
    const { lastPage } = useSelector(state => state.pages);

    const startDispatch = () => {
        const params = new URLSearchParams(location.search);
        const lid = params.get('lid');

        const shop_id = page?.isShopOrientedPage === true ? '?shop_id=' + localStorage.getItem('shop_id') : '';
        const pagination = page?.hasPagination === true ? shop_id !== '' ? shop_id + '&page=0' : '?page=0' : shop_id;
        const lidParam = lid !== null ? pagination !== '' ? pagination + '&lid=' + lid : '?lid=' + lid : pagination;

        if (lastPage?.split('/')?.[2] === page.collection && items.length !== 0) {
            return;
        }

        dispatch(fetchList(page.collection + '/' + token + lidParam));
        dispatch(setUpdateItems([]));
        dispatch(setLastUpdateTime(Date.now()));
    }

    useEffect(() => {
        if (token) {
            if (page?.isShopOrientedPage === true && localStorage.getItem('shop_id') === null) {
                dispatch(setStatus(null));
                return;
            }

            startDispatch();
        } else {
            navigate('/login');
        }
    }, [dispatch, page?.collection, token, location]);

    const { scrollTop } = useSelector(state => state.list.scrollTop);
    useEffect(() => {
        const mainElement = document.getElementsByTagName('main')[0];
        mainElement.scrollTo(0, 0);
        if (lastPage?.split('/')?.[2] === page.collection && items.length !== 0) {
            setTimeout(() => {
                if (mainElement.scrollHeight > scrollTop) {
                    mainElement.scrollTo(0, scrollTop || 0);
                } else {
                    setTimeout(() => {
                        if (mainElement.scrollHeight > scrollTop) {
                            mainElement.scrollTo(0, scrollTop || 0);
                        } else {
                            setTimeout(() => {
                                if (mainElement.scrollHeight > scrollTop) {
                                    mainElement.scrollTo(0, scrollTop || 0);
                                }
                            }, 100);
                        }
                    }, 10);
                }
            }, 0);
        } else {
            dispatch(setSort({}));
            //setFilter({});
            dispatch(setSearch(''));
            dispatch(setShowedControl());
        }
    }, [page?.collection]);

    useEffect(() => {
        if (elementStatus === 'loading') {
            dispatch(setStatus('loading'));
        }
        if (elementStatus === 'deleted') {
            if (page?.isShopOrientedPage === true && localStorage.getItem('shop_id') === null) {
                dispatch(setStatus(null));
                return;
            }
            dispatch(fetchList(page.collection + '/' + token + (page?.isShopOrientedPage === true ? '?shop_id=' + localStorage.getItem('shop_id') : '')));
            dispatch(setElementStatus(null));
        }
    }, [page.collection, elementStatus, dispatch, token, page?.isShopOrientedPage]);

    const onContextMenu = (e, targetItem, item) => {
        setElement(item);
        setTarget(targetItem);
        setContextStyles({
            display: 'block',
            top: document.body.clientHeight - e.clientY > 100 ? e.clientY : 'auto',
            bottom: document.body.clientHeight - e.clientY > 100 ? 'auto' : '10px',
            left: document.body.clientWidth - e.clientX > 200 ? e.clientX : 'auto',
            right: document.body.clientWidth - e.clientX > 200 ? 'auto' : '10px',
        });
    }

    const handleOutsideClick = e => {
        if (Array.from(e.target.parentElement.classList).includes('context-menu')) {
            return;
        }
        setContextStyles({ display: 'none' });
    }
    const handleKeyDown = (e) => {
        const index = Array.from(document.getElementsByClassName('list-item')).indexOf(document.activeElement);
        if (isOpen) {
            if (e.key === 'Escape') {
                setOpen(false);
                return;
            }
            if (e.key === 'Enter') {
                setOpen(false);
                dispatch(fetchDeleteElement(page.collection + '/' + localStorage.getItem('token') + '?item=' + element?._id?.toString() + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : '')));
                return;
            }
            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                if (!isOpen) {
                    return;
                }
                if (Array.from(document.getElementsByClassName('dialog-button')).indexOf(document.activeElement) === -1) {
                    document.getElementsByClassName('dialog-button')[0].focus();
                    return;
                }
                if (document.getElementsByClassName('dialog-button')[0] === document.activeElement) {
                    document.getElementsByClassName('dialog-button')[1].focus();
                    return;
                }
                document.getElementsByClassName('dialog-button')[0].focus();
            }
        }
        if (e.key === 'ArrowDown') {
            if (!document.activeElement.classList.contains('list-item')) {
                document.getElementsByClassName('list-item')[0].focus();
                setElement(itemsState[0]);
                return;
            }
            if (index < document.getElementsByClassName('list-item').length - 1) {
                document.getElementsByClassName('list-item')[index + 1].focus();
                setElement(itemsState[index + 1]);
            }
        }
        if (e.key === 'ArrowUp') {
            if (document.activeElement.classList.contains('list-item')) {
                if (index > 0) {
                    document.getElementsByClassName('list-item')[index - 1].focus();
                    setElement(itemsState[index - 1]);
                }
            }
        }
        if (e.key === 'Insert' || e.key === '+') {
            if (pageData.canAdd === true) {
                navigate('/add/' + page.collection);
            }
        }
        if (e.key === 'Delete' || e.key === '-') {
            if (element?.canDelete !== false && document.activeElement.classList.contains('list-item')) {
                setOpen(true);
            }
        }
        if (e.key === 'F2') {
            if (element?.canEdit !== false) {
                navigate('/edit/' + page.collection + '/' + element?._id?.toString());
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
            document.removeEventListener('keydown', handleKeyDown);
        }
    });

    const [isOpen, setOpen] = useState(false);

    const { search: searchValue, sort } = useSelector(state => state.list);

    const [filter, setFilter] = useState({});
    const [activeFilter, setActiveFilter] = useState();

    const emptyText = ' Пусто';

    const closeIconRef = useRef(null);
    const handleInputChange = (e) => {
        if (e.target.value === '') {
            closeIconRef.current.classList.add('hideSearch');
            dispatch(fetchList(page.collection + '/' + localStorage.getItem('token') + '?search=&sort=' + JSON.stringify(sort) + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : '')));
            setTimeout(() => {
                searchInputRef.current?.focus();
            }, 500);
        } else {
            closeIconRef.current.classList.remove('hideSearch');
        }
        dispatch(setSearch(e.target.value));
    }

    const handleSearch = () => {
        dispatch(fetchList(page.collection + '/' + localStorage.getItem('token') + '?search=' + searchValue + '&sort=' + JSON.stringify(sort) + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : '')));
    }

    const handleSort = (key) => {
        if (sort[key] === '-1') {
            dispatch(setSort({}));
            dispatch(fetchList(page.collection + '/' + localStorage.getItem('token') + '?search=' + searchValue + '&sort=' + JSON.stringify({}) + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : '')));
            return;
        }
        const newSort = { [key]: sort[key] === '1' ? '-1' : '1' };
        dispatch(setSort(newSort));
        dispatch(fetchList(page.collection + '/' + localStorage.getItem('token') + '?search=' + searchValue + '&sort=' + JSON.stringify(newSort) + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : '')));
    }

    const handleFilter = (key, value) => {
        setFilter({ ...filter, [key]: value });
        setItemsState({ filters: { ...filter, [key]: value } });
    }

    const [modalClasses, setModalClasses] = useState('modal-base-container modal-hide');
    const hideModal = () => {
        setModalClasses('modal-base-container modal-hide');
    }

    const { showedControl } = useSelector(state => state.list);

    const handleFilterButtonClick = (key, e) => {
        if (e?.target?.alt === 'resetFilter') {
            const clearFilter = { ...filter };
            delete clearFilter[key];
            setItemsState({ filters: clearFilter });
            setFilter(clearFilter);
            setActiveFilter();
            return;
        }
        setActiveFilter(key);
        setModalClasses('modal-base-container');
    }

    const [scrolledDown, setScrolledDown] = useState(false);
    useEffect(() => {
        if (scrolledDown) {
            if (pagination.pageNumber >= pagination.pagesCount || pagination.status === 'loading') {
                return;
            }
            const searchParams = new URLSearchParams(location);
            const lid = searchParams.get('lid');
            dispatch(fetchAdditionalElements(page.collection + '/' + token + '?search=' + searchValue + '&sort=' + JSON.stringify(sort) + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') + (page?.hasPagination === true ? '&sliceNumber=' + (pagination.pageNumber + 1) : '') : page?.hasPagination === true ? '&sliceNumber=' + (pagination.pageNumber + 1) : '') + (lid !== null ? '&lid=' + lid : '')));
            setScrolledDown(false);
        }
    }, [scrolledDown, dispatch, page.collection, token, page?.isShopOrientedPage, page?.hasPagination, pagination.pageNumber, pagination.pagesCount, pagination.status]);

    useEffect(() => {
        const loadElements = () => {
            if (pagination.pageNumber < pagination.pagesCount && pagination.status !== 'loading') {
                setScrolledDown(true);
            }
        }
        const mainElement = document.getElementsByTagName('main')[0];
        mainElement !== undefined && mainElement.addEventListener('scroll', e => {
            if ((mainElement.scrollTop + mainElement.clientHeight) / mainElement.scrollHeight >= 0.9) {
                if (pagination.pageNumber < pagination.pagesCount) {
                    loadElements();
                }
            }
        });

        return () => {
            const mainElement = document.getElementsByTagName('main')[0];
            mainElement !== undefined && mainElement.addEventListener('scroll', e => {
                if ((mainElement.scrollTop + mainElement.clientHeight) / mainElement.scrollHeight >= 0.9) {
                    if (pagination.pageNumber < pagination.pagesCount) {
                        loadElements();
                    }
                }
            });
        }
    }, [pagination, dispatch]);

    const { status: updatesStatus, items: updatesItems, lastUpdateTime } = useSelector(state => state.list.updates);
    useEffect(() => {
        const interval = setInterval(() => {
            if (updatesStatus === 'loading') {
                return;
            }
            if (pageData.updateByInterval !== true) {
                return;
            }
            if (JSON.stringify(sort) !== JSON.stringify({}) || searchValue !== '') {
                return;
            }
            const searchParams = new URLSearchParams(location);
            const lid = searchParams.get('lid');
            dispatch(fetchUpdates(page.collection + '/' + token + '?search=' + searchValue + '&sort=' + JSON.stringify(sort) + '&update=true&lastUpdateTime=' + lastUpdateTime + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : '') + (lid !== null ? '&lid=' + lid : '')));
        }, 10000);

        return () => clearInterval(interval);
    }, [updatesStatus, pageData.updateByInterval]);

    useEffect(() => {
        if (updatesStatus !== 'success' || updatesItems?.length === 0) {
            return;
        }

        if (updatesItems === undefined) {
            return;
        }
        const resultItems = itemsState.map(item => {
            const updatedItem = updatesItems?.find(updateItem => updateItem._id === item._id);
            if (updatedItem === undefined) {
                return item;
            }
            return updatedItem;
        });

        updatesItems?.forEach(updateItem => {
            const resultItem = resultItems?.find(resultItem => resultItem._id === updateItem._id);
            if (resultItem === undefined && updateItem !== undefined && updateItem.created > resultItems[0]?.created) {                
                resultItems.unshift(updateItem);
            }
        });

        if (JSON.stringify(sort) !== JSON.stringify({}) || searchValue !== '') {
            return;
        }

        if (resultItems === undefined || resultItems?.length === 0) {
            return;
        }

        setItemsToState(resultItems);
        dispatch(setLastUpdateTime(Date.now()));
    }, [updatesStatus, updatesItems]);

    const searchInputRef = useRef();

    const { shops, status: shopStatus } = useSelector(state => state.shop);
    useEffect(() => {
        if (shopStatus === null) {
            dispatch(fetchShops(localStorage.getItem('token')));
            return;
        }
        if (shopStatus === 'success' && localStorage.getItem('shop_id') === null) {
            const shop = shops[0];
            dispatch(setShop(shop));
            startDispatch();
        }
    }, [shopStatus, shops, dispatch]);

    return (
        <div className='list-container' ref={listContainerRef}>
            <Modal modalClasses={modalClasses} hideModal={hideModal} style={{ width: '400px', height: '500px' }} title={'Фильтр'}>
                <div className='list-filter-container'>
                    {
                        itemsState.map(item => (activeFilter === 'created' || activeFilter === 'updated') ? (item[activeFilter] ? new Date(item[activeFilter]).toLocaleString() : emptyText) : (item[activeFilter] ? item[activeFilter] : emptyText)).filter((item, index, self) => self.indexOf(item) === index).sort((a, b) => typeof a === 'string' ? a.localeCompare(b) : a - b).map((item, index) => {
                            return (
                                <div key={index} className='list-item fit-content-height filter-list-item' onClick={() => { handleFilter(activeFilter, item); hideModal(); }}>
                                    <span>{((activeFilter === 'created' || activeFilter === 'updated') ? (item !== undefined ? new Date(item).toLocaleString() : emptyText) : ((item !== undefined && item !== null && item !== '') ? item : emptyText))}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
            {
                (page?.isShopOrientedPage === true && localStorage.getItem('shop_id') === null && status !== 'loading') &&
                <div className="flex-center align-center flex-column">
                    <ImageComponent src={emptyShop} alt="" width={100} />
                    <p className="flex-center align-center description">Для просмотра страницы необходимо<NavLink to="/shop" className={'button min-width small-padding small-left-margin align-center'}>выбрать магазин</NavLink></p>
                </div>
            }
            {
                (page?.isShopOrientedPage !== true || localStorage.getItem('shop_id') !== null) &&
                <div>
                    {
                        status === 'success' &&
                        <div className="list-header" style={{ marginBottom: '5px' }}>
                            <h3>{page?.title}</h3>
                            <div className='flex-start'>
                                {
                                    (pageData?.accesses?.canAdd === true && status === 'success') &&
                                    <button className='button list-add' onClick={() => navigate('/add/' + page.collection)}>
                                        <img src={addIcon} alt="" width={25} />
                                        Добавить
                                    </button>
                                }
                            </div>
                        </div>
                    }
                    <div className='list'>
                        {
                            (status === 'success' && page?.title === pageData?.title) &&
                            <div>
                                <div className="header-controls full-width">
                                    <div className="controls-container flex align-center">
                                        {
                                            showedControl === 'search' &&
                                            <div className='flex-start align-center full-width control-container'>
                                                <div className='list-search-input-container'>
                                                    <input ref={searchInputRef} type="text" placeholder='Поиск' value={searchValue} onChange={handleInputChange} onKeyDown={e => e.key === 'Enter' && handleSearch()} />
                                                    <div ref={closeIconRef} tabIndex={0} className={"list-search-close-icon" + (searchValue === '' ? ' hideSearch' : '')} onClick={() => { dispatch(setSearch('')); closeIconRef.current.classList.add('hideSearch'); dispatch(fetchList(page.collection + '/' + localStorage.getItem('token') + '?search=&sort=' + JSON.stringify(sort) + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : ''))); }}>
                                                        <img src={closeIcon} alt="" />
                                                    </div>
                                                    <div className="list-search-icon" tabIndex={0} onClick={() => handleSearch()} onKeyDown={e => e.key === 'Enter' && handleSearch()}>
                                                        <img src={searchIcon} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            showedControl === 'filter' &&
                                            <div className='flex-start align-center full-width control-container'>
                                                {
                                                    pageData?.components?.[(pageData?.listTitle?.split('.')?.[0] !== undefined ? pageData?.listTitle?.split('.')?.[0] : pageData?.listTitleIfUndefined?.split('.')?.[0])]?.label !== undefined && pageData?.components?.[(pageData?.listTitle?.split('.')?.[0])]?.label !== undefined &&
                                                    <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (filter[pageData?.listTitle] !== undefined ? '  button-primary' : '')} onClick={e => handleFilterButtonClick(pageData?.listTitle, e)}>
                                                        <span>{pageData?.components?.[(pageData?.listTitle?.split('.')?.[0])]?.label}{(filter[pageData?.listTitle] !== undefined ? ': ' + filter[pageData?.listTitle] : '')}</span>
                                                        {
                                                            filter[pageData?.listTitle] !== undefined &&
                                                            <div className='filter-clear'>
                                                                <img src={closeIcon} alt="resetFilter" width={10} />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    pageData?.components?.[(pageData?.listSubtitle?.split('.')?.[0])]?.label !== undefined &&
                                                    <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (filter[pageData?.listSubtitle] !== undefined ? '  button-primary' : '')} onClick={e => handleFilterButtonClick(pageData?.listSubtitle, e)}>
                                                        <span>{pageData?.components?.[(pageData?.listSubtitle?.split('.')?.[0])]?.label}{(filter[pageData?.listSubtitle] !== undefined ? ': ' + filter[pageData?.listSubtitle] : '')}</span>
                                                        {
                                                            filter[pageData?.listSubtitle] !== undefined &&
                                                            <div className='filter-clear'>
                                                                <img src={closeIcon} alt="resetFilter" width={10} />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    (pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label !== undefined || (pageData?.listDateTime?.split('.')?.[0]) === 'created' || (pageData?.listDateTime?.split('.')?.[0]) === 'updated') &&
                                                    <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (filter[pageData?.listDateTime] !== undefined ? '  button-primary' : '')} onClick={e => handleFilterButtonClick(pageData?.listDateTime, e)}>
                                                        <span>{(pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label !== undefined ? pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label : (pageData?.listDateTime?.split('.')?.[0]) === 'created' ? 'Дата создания' : 'Дата обновления')}{(filter?.[pageData?.listDateTime] !== undefined ? ': ' + (((pageData?.listDateTime === 'created' || pageData?.listDateTime === 'updated') ? new Date(filter?.[pageData?.listDateTime]).toLocaleString() : filter?.[pageData?.listDateTime])) : '')}</span>
                                                        {
                                                            filter[pageData?.listDateTime] !== undefined &&
                                                            <div className='filter-clear'>
                                                                <img src={closeIcon} alt="resetFilter" width={10} />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    pageData?.components?.[(pageData?.listSum?.split('.')?.[0])]?.label !== undefined &&
                                                    <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (filter[pageData?.listSum] !== undefined ? '  button-primary' : '')} onClick={e => handleFilterButtonClick(pageData?.listSum, e)}>
                                                        <span>{pageData?.components?.[(pageData?.listSum?.split('.')?.[0])]?.label}{(filter[pageData?.listSum] !== undefined ? ': ' + filter[pageData?.listSum] : '')}</span>
                                                        {
                                                            filter[pageData?.listSum] !== undefined &&
                                                            <div>
                                                                <img src={closeIcon} alt="resetFilter" width={10} />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            showedControl === 'sort' &&
                                            <div className='flex-start align-center full-width control-container'>
                                                {
                                                    pageData?.components?.[(pageData?.listTitle?.split('.')?.[0])]?.label !== undefined &&
                                                    <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (sort[pageData?.listTitle] !== undefined ? '  button-primary' : '')} onClick={() => handleSort((pageData?.listTitle?.split('.')?.[0]))}>
                                                        {
                                                            sort[(pageData?.listTitle?.split('.')?.[0])] === '1' && <img src={sortAsc} alt="" height={20} className='small-left-margin' />
                                                        }
                                                        {
                                                            sort[(pageData?.listTitle?.split('.')?.[0])] === '-1' && <img src={sortDesc} alt="" height={20} className='small-left-margin' />
                                                        }
                                                        <span>{pageData?.components?.[(pageData?.listTitle?.split('.')?.[0])]?.label}</span>
                                                    </div>
                                                }
                                                {
                                                    pageData?.components?.[(pageData?.listSubtitle?.split('.')?.[0])]?.label !== undefined &&
                                                    <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (sort[pageData?.listSubtitle] !== undefined ? '  button-primary' : '')} onClick={() => handleSort(pageData?.listSubtitle)}>
                                                        {
                                                            sort[pageData?.listSubtitle] === '1' && <img src={sortAsc} alt="" height={20} className='small-left-margin' />
                                                        }
                                                        {
                                                            sort[pageData?.listSubtitle] === '-1' && <img src={sortDesc} alt="" height={20} className='small-left-margin' />
                                                        }
                                                        <span>{pageData?.components?.[(pageData?.listSubtitle?.split('.')?.[0])]?.label}</span>
                                                    </div>
                                                }
                                                {
                                                    (pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label !== undefined || (pageData?.listDateTime?.split('.')?.[0]) === 'created' || (pageData?.listDateTime?.split('.')?.[0]) === 'updated') &&
                                                    <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (sort[pageData?.listDateTime] !== undefined ? '  button-primary' : '')} onClick={() => handleSort((pageData?.listDateTime?.split('.')?.[0]))}>
                                                        {
                                                            sort[(pageData?.listDateTime?.split('.')?.[0])] === '1' && <img src={sortAsc} alt="" height={20} className='small-left-margin' />
                                                        }
                                                        {
                                                            sort[(pageData?.listDateTime?.split('.')?.[0])] === '-1' && <img src={sortDesc} alt="" height={20} className='small-left-margin' />
                                                        }
                                                        <span>{(pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label !== undefined ? pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label : (pageData?.listDateTime?.split('.')?.[0]) === 'created' ? 'Дата создания' : 'Дата обновления')}</span>
                                                    </div>
                                                }
                                                {
                                                    pageData?.components?.[(pageData?.listSum?.split('.')?.[0])]?.label !== undefined &&
                                                    <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (sort[pageData?.listSum] !== undefined ? '  button-primary' : '')} onClick={() => handleSort((pageData?.listSum?.split('.')?.[0]))}>
                                                        {
                                                            sort[(pageData?.listSum?.split('.')?.[0])] === '1' && <img src={sortAsc} alt="" height={20} className='small-left-margin' />
                                                        }
                                                        {
                                                            sort[(pageData?.listSum?.split('.')?.[0])] === '-1' && <img src={sortDesc} alt="" height={20} className='small-left-margin' />
                                                        }
                                                        <span>{pageData?.components?.[(pageData?.listSum?.split('.')?.[0])]?.label}</span>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            showedControl !== undefined &&
                                            <button className="button color-primary small-left-margin primary-background-color-light cursor-pointer small-padding" style={{ height: 'fit-content', marginBottom: '5px' }} onClick={() => dispatch(setShowedControl())}>
                                                <img src={closeIcon} alt="" width={15} />
                                            </button>
                                        }
                                    </div>

                                    {
                                        showedControl === undefined &&
                                        <div className='list-controls flex-start align-center'>
                                            <div className="button small-padding small-margin cursor-pointer releative" onClick={() => dispatch(setShowedControl('sort'))}>
                                                <img src={sortIcon} alt="" width={20} />
                                                <span className='small-left-margin color-primary'>Сортировка</span>
                                                {
                                                    Object.values(sort).filter(value => value !== '0')?.length > 0 &&
                                                    <span className='required push-point'>●</span>
                                                }
                                            </div>
                                            {/* <div className="button small-padding small-margin cursor-pointer releative" onClick={() => setShowedControl('filter')}>
                                                <img src={filterIcon} alt="" width={20} />
                                                <span className='small-left-margin color-primary'>Фильтр</span>
                                                {
                                                    Object.values(filter)?.length > 0 &&
                                                    <span className='required push-point'>●</span>
                                                }
                                            </div> */}
                                            <div className="button small-padding small-margin cursor-pointer releative" onClick={() => { dispatch(setShowedControl('search')); setTimeout(() => searchInputRef?.current?.focus(), 100) }}>
                                                <img src={searchIcon} alt="" width={20} />
                                                <span className='small-left-margin color-primary'>Поиск</span>
                                                {
                                                    searchValue?.length > 0 &&
                                                    <span className='required push-point'>●</span>
                                                }
                                            </div>
                                            {
                                                pageData?.isShopOrientedPage === true &&
                                                <ShopSwitchter dispathcher={startDispatch} />
                                            }
                                        </div>
                                    }
                                </div>
                                {
                                    status === 'success' &&
                                    <ListElements items={itemsState} list={page} onContextMenu={onContextMenu} />
                                }
                                {
                                    itemsState.length === 0 &&
                                    <div className='description flex-center'>Список пуст</div>
                                }
                            </div>
                        }
                        <Dialog
                            isOpen={isOpen}
                            title="Удаление"
                            text='Вы дейстивительно хотите удалить этот элемент?'
                            onCancel={
                                () => {
                                    setOpen(false);
                                }
                            }
                            onOk={
                                () => {
                                    setOpen(false);
                                    dispatch(fetchDeleteElement(page.collection + '/' + localStorage.getItem('token') + '?item=' + element?._id?.toString() + (page?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : '')));
                                }
                            }
                        />
                        <Context setOpen={setOpen} target={target} style={contextStyles} elements={[{ label: 'Скопировать', disabled: !pageData?.accesses?.canAdd, action: 'copy' }, { label: 'Редактировать', disabled: !(pageData?.accesses?.canEdit && element?.canEdit !== false), action: 'edit' }, { label: 'Удалить', disabled: !(pageData?.accesses?.canDelete && element?.canDelete !== false), action: 'delete' }]} />
                    </div>
                </div>
            }

            <div className='list'>
                {
                    status === 'error' &&
                    <div className='error'>Произошла ошибка при получении данных</div>
                }
                {
                    status === 'loading' &&
                    <div className="list-header" style={{ marginBottom: '5px' }}>
                        <h3>Загрузка...</h3>
                        <div className='flex-start'>
                            {
                                (pageData?.accesses?.canAdd === true && status === 'success') &&
                                <button className='button list-add' onClick={() => navigate('/add/' + page.collection)}>
                                    <img src={addIcon} alt="" width={20} />
                                    Добавить
                                </button>
                            }
                        </div>
                    </div>
                }
                {
                    (status === 'loading' || (page?.title !== pageData?.title && status === 'success')) &&
                    <div>
                        {
                            Array.from({ length: 10 }).map((item, index) => {
                                return (
                                    <div key={index} className='list-item loading'>
                                        <div className="list-icon"></div>
                                        <div className="list-info loading">
                                            <div className="list-titles">
                                                <p className="list-title loading"></p>
                                                <p className='list-subtitle loading'></p>
                                            </div>
                                            <div className="list-overalls">
                                                <p className="list-date-time loading"></p>
                                                <p className="list-sum loading"></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    pagination.status === 'loading' &&
                    <div>
                        {
                            Array.from({ length: 1 }).map((item, index) => {
                                return (
                                    <div key={index} className='list-item loading'>
                                        <div className="list-icon"></div>
                                        <div className="list-info loading">
                                            <div className="list-titles">
                                                <p className="list-title loading"></p>
                                                <p className='list-subtitle loading'></p>
                                            </div>
                                            <div className="list-overalls">
                                                <p className="list-date-time loading"></p>
                                                <p className="list-sum loading"></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default List
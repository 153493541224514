import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWidgetsList } from './widgets.slice';
import ShopSwitchter from '../ShopSwitchter/ShopSwitchter';
import './Widgets.css';

import LowVolumeGoodsWidget from './LowVolumeGoodsWidget';
import ServerLoad from './ServerLoad';
import ServerLoadChart from './ServerLoadChart';
import SalesChart from './SalesChart';


function Widgets() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const shop_id = localStorage.getItem('shop_id');

  useEffect(() => {
    if (shop_id === null) {
      return;
    }
    if (status === 'success') {
      return;
    }
    dispatch(fetchWidgetsList(token + '?shop_id=' + shop_id));
  }, [dispatch, token, shop_id]);
  const { widgets, status } = useSelector((state) => state.widgets);
  const [descriptionClassname, setDescriptionClassname] = useState('no-expanded-widget');

  const { scrollTopPosition } = useSelector(state => state.pages.home);
  useEffect(() => {
    const mainElement = document.getElementsByTagName('main')[0];
    if (scrollTopPosition !== undefined) {
      setTimeout(() => mainElement.scrollTo(0, scrollTopPosition), 0);
    }
  });

  return (
    <div>
      {
        status === 'success' &&
        widgets.map((widget, index) => {
          let component;

          switch (widget.component) {
            case 'lowVolumeGoodsWidget':
              component = <LowVolumeGoodsWidget />;
              break;
            case 'serverLoad':
              component = <ServerLoad />;
              break;
            case 'salesChart':
              component = <SalesChart />;
              break;
            case 'serverLoadChart':
              component = <ServerLoadChart />;
              break;
            default:
              break;
          }
          return (
            <div key={index} className='widget'>
              <div className='widget-header flex-space-between align-center'>
                <h4 className='widget-title small-margin'>{widget.title}</h4>
                {
                  widget.isShopOriented === true &&
                  <ShopSwitchter onSwitch={() => { dispatch(fetchWidgetsList(token + '?shop_id=' + shop_id)) }} />
                }
              </div>
              {
                (widget.description !== undefined && widget.description.length > 0) &&
                <div className={descriptionClassname + ' relative'}>
                  <p className={'description small-font-size small-margin ' + descriptionClassname}>{widget.description}</p>
                  <button
                    className='button widget-expand-button small-font-size absolute'
                    onClick={() => {
                      if (descriptionClassname === 'no-expanded-widget') {
                        setDescriptionClassname('expanded-widget')
                      } else {
                        setDescriptionClassname('no-expanded-widget')
                      }
                    }}
                  >
                    {descriptionClassname === 'no-expanded-widget' ? 'Подробнее' : 'Скрыть'}
                  </button>
                </div>
              }

              <div className='main-widget'>
                {component}
              </div>
            </div>
          )
        })
      }
      {
        status === 'loading' &&
        <div className='widget loading'></div>
      }
    </div>
  )
}

export default Widgets
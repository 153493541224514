import React, { useRef } from 'react';
import './Context.css';
import { useNavigate } from 'react-router-dom';

function Context({ elements, style, target, setOpen }) {
    const navigate = useNavigate();
    const contextMenuRef = useRef(null);
    const buttonOnClick = (evt, element) => {
        setTimeout(() => {
            if (typeof target === 'object') {
                switch (element.action) {
                    case 'copy': target.copy(); break;
                    case 'edit': target.edit(); break;
                    case 'delete': target.delete(); break;
                    default: break;
                }
                return;
            }

            switch (element.action) {
                case 'copy': navigate('/add/' + target); break;
                case 'edit': navigate('/edit/' + target); break;
                case 'delete': setOpen(true); break;
                default: break;
            }
        }, 200);
    }
    return (
        <div ref={contextMenuRef} className={'context-menu'} style={style}>
            {
                elements.map((element, index) => {
                    return (
                        <button className="button" key={index} disabled={element.disabled} onClick={e => { buttonOnClick(e, element); }} onTouchStart={e => { buttonOnClick(e, element); }} onMouseDown={e => { buttonOnClick(e, element); }}>{element.label}</button>
                    )
                })
            }
        </div>
    )
}

export default Context
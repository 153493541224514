import React, { useEffect, useRef, useState } from 'react'
import ListElements from '../List/ListElements';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLowVolumeGoods, setActiveFilterIndex, setExpanded } from './widgets.slice';
import ImageComponent from '../ImageComponent/ImageComponent';
import './Widgets.css';

import expandListIcon from '../../images/list-expand.png';
import hideListIcon from '../../images/list-hide.png';

function LowVolumeGoodsWidget() {
    const filters = [
        {
            label: '0-2 дня',
            maxValue: 2,
        },
        {
            label: '3-6 дней',
            maxValue: 6,
            minValue: 3,
        },
        {
            label: '7-19 дней',
            maxValue: 19,
            minValue: 7,
        },
        {
            label: '20-29 дней',
            maxValue: 29,
            minValue: 20,
        },
        {
            label: '30 дней и более',
            minValue: 30,
        },
    ];

    const dispatch = useDispatch();

    const { items, status, activeFilterIndex } = useSelector(state => state.widgets.lowVolumeGoodsWidget);
    const { shop, status: shopStatus } = useSelector(state => state.shop);

    const startDispatch = (shop) => {
        const token = localStorage.getItem('token');
        const shop_id = shop?._id;
        if (shop_id === undefined) {
            return;
        }
        dispatch(fetchLowVolumeGoods(token + '?shop_id=' + shop_id + '&pagination=false&sort={"totalBalanceKg":-1}'));
    };

    const { expanded } = useSelector(state => state.widgets.lowVolumeGoodsWidget);

    useEffect(() => {
        if (status === 'success') {
            setItemsState(items.filter(item => item.enoughDays !== null && item.enoughDays !== undefined && (filters[activeFilterIndex].minValue === undefined || item.enoughDays >= filters[activeFilterIndex].minValue) && (item.enoughDays <= filters[activeFilterIndex].maxValue || filters[activeFilterIndex].maxValue === undefined)));
        }
    }, [status]);

    useEffect(() => {
        if (shopStatus === 'success') {
            startDispatch(shop);
        }
    }, [dispatch, shop, shopStatus]);

    const [itemsState, setItemsState] = useState([]);

    useEffect(() => {
        if (activeFilterIndex === undefined) {
            return;
        }
        setItemsState(items.filter(item => item.enoughDays !== null && item.enoughDays !== undefined && (filters[activeFilterIndex].minValue === undefined || item.enoughDays >= filters[activeFilterIndex].minValue) && (item.enoughDays <= filters[activeFilterIndex].maxValue || filters[activeFilterIndex].maxValue === undefined)));
        widgetListContainerRef.current?.scrollTo(0, 0);
    }, [activeFilterIndex]);

    const widgetListContainerRef = useRef(null);
    const [clientHeight, setClientHeight] = useState(document.getElementsByTagName('main')[0]?.clientHeight);
    const [scrollHeight, setScrollHeight] = useState(widgetListContainerRef.current?.scrollHeight);

    const setExpandedState = () => {
        const clientHeight = document.getElementsByTagName('main')[0]?.clientHeight;
        const scrollHeight = widgetListContainerRef.current?.scrollHeight;
        setClientHeight(clientHeight);
        setScrollHeight(scrollHeight);        
        if (clientHeight !== undefined && scrollHeight !== undefined && scrollHeight > clientHeight) {
            if (expanded !== undefined) {
                return;
            }
            dispatch(setExpanded(false));
        } else {
            if (scrollHeight <= clientHeight) {
                dispatch(setExpanded());        
            }
        }
    }

    useEffect(() => {
        setExpandedState();
    }, [clientHeight, scrollHeight, status]);
    return (
        <div style={{ height: '100%' }}>
            <div className="header-filters flex-start align-center medium-margin-top overflow-x-auto">
                {
                    filters.map((filter, index) => (
                        <button
                            className={"button header-filter-button small-font-size small-margin color-primary no-wrap-white-space" + (activeFilterIndex === index ? ' active-filter' : '')}
                            key={index}
                            onClick={() => { dispatch(setActiveFilterIndex(index)); setTimeout(setExpandedState, 100) }}>
                            {filter.label}
                        </button>
                    ))
                }
            </div>

            {
                status === 'success' &&
                <div>
                    <div ref={widgetListContainerRef} className='widget-list-container' style={{ height: expanded === false ? (clientHeight - 400) + 'px' : 'fit-content', overflow: 'hidden' }}>
                        <ListElements items={itemsState.map(item => { return { ...item, link: '/view/goods/' + item._id.toString() } }).sort((a, b) => a.enoughDays - b.enoughDays)} list={{ listTitle: 'title', listSum: 'totalBalanceKg', listSumAddition: ' кг' }} hasLink={true} />

                        {
                            itemsState.length === 0 &&
                            <p className='description small-font-size full-width justify-center align-center text-align-center'>Список пуст</p>
                        }

                        {
                            itemsState.length > 0 &&
                            <p className='description small-font-size full-width justify-center align-center text-align-center'>Всего {itemsState.length} товаров</p>
                        }
                    </div>
                    {
                        expanded !== undefined &&
                        <div className={'full-width flex-center ' + (expanded === false ? 'widget-expand-button-shadow' : '')}>
                            <button
                                className='button'
                                onClick={() => {
                                    dispatch(setExpanded(!expanded));
                                }}
                            >
                                <ImageComponent src={expanded ? hideListIcon : expandListIcon} width={25} />
                                {expanded ? 'Показать меньше' : 'Показать ещё'}
                            </button>
                        </div>
                    }
                </div>
            }

            {
                status === 'loading' &&
                <p className='description small-font-size full-width justify-center align-center text-align-center'>Загрузка...</p>
            }
        </div>
    )
}

export default LowVolumeGoodsWidget
import React, { useState } from 'react';

const ImageComponent = ({ src, alt, height, width, style, className }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleLoad = () => {
        setIsLoading(false); // Image has loaded
    };

    const handleError = () => {
        setIsLoading(false); // Image failed to load
    };

    return (
        <img
            src={src}
            alt={alt}
            height={height ?? 'auto'}
            width={width ?? 'auto'}
            style={style ?? { minWidth: (width ?? height ?? 'auto'), minHeight: (height ?? width ?? 'auto') }}
            className={isLoading ? (className ?? '') + ' loading' : (className ?? '')} // Apply loading class if still loading
            onLoad={handleLoad}
            onError={handleError}
        />
    );
};

export default ImageComponent;

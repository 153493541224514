import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchServerLoadChart, setAFIServerLoadChart } from './widgets.slice';
import Chart from '../Chart/Chart';
import './Widgets.css';
import { splitValue } from '../Number/Number';

function ServerLoadChart() {
  const filters = [
    {
      label: 'Сегодня',
      maxValue: 24 * 60 * 60,
      filterLabel: 'today',
    },
    {
      label: 'Неделя',
      maxValue: 7 * 24 * 60 * 60,
      filterLabel: 'week',
    },
    {
      label: 'Месяц',
      maxValue: 30 * 24 * 60 * 60,
      filterLabel: 'month',
    }
  ];

  const dispatch = useDispatch();

  const [itemsState, setItemsState] = useState([]);

  const { items, status, activeFilterIndex } = useSelector(state => state.widgets.serverLoadChart);

  useEffect(() => {
    if (status === 'success') {
      const groupedData = groupData(Object.entries(items).map(([key, value]) => ({ time: key, count: value })), filters[activeFilterIndex].filterLabel);
      setItemsState(groupedData);
    }
  }, [status]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(fetchServerLoadChart(token + '?interval=' + filters[activeFilterIndex].maxValue + '&byTime=true'));
  }, [activeFilterIndex]);
  function groupData(data, filter) {
    const result = {};

    // Преобразуем строки времени в объекты Date
    const parsedData = data.map(item => ({
      ...item,
      date: new Date(new Date(item.time + ':00').setHours(+item.time.split('T')[1] + 3)),
    }));

    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Понедельник

    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    parsedData.forEach(item => {
      let key;

      switch (filter) {
        case 'today':
          if (item.date.toDateString() === today.toDateString()) {
            key = item.date.getHours().toString().padStart(2, '0') + ':00';
            result[key] = (result[key] || 0) + item.count;
          }
          break;

        case 'week':
          if (item.date >= startOfWeek) {
            key = item.date.toLocaleString('ru-RU', { weekday: 'long' });
            result[key] = (result[key] || 0) + item.count;
          }
          break;

        case 'month':
          if (item.date >= startOfMonth) {
            key = item.date.toLocaleDateString('ru-RU');
            result[key] = (result[key] || 0) + item.count;
          }
          break;

        default:
          throw new Error('Unknown filter type');
      }
    });
    // Преобразуем объект результата в массив
    return Object.entries(result).map(([time, count]) => ({ time, count }));
  }

  function CustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', color: '#0076FE', border: '1px solid var(--primary-border-color)', padding: '5px' }}>
          <p style={{ padding: 0, margin: 0, marginBottom: '5px' }}>{payload[0]?.payload?.time}</p>
          <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>{'Запросы: ' + splitValue(payload[0]?.value)}</p>
        </div>
      );
    }

    return null;
  }
  return (
    <div style={{ height: '300px' }}>
      <div className="header-filters flex-start align-center medium-margin-top overflow-x-auto">
        {
          filters.map((filter, index) => (
            <button
              className={"button header-filter-button small-font-size small-margin color-primary no-wrap-white-space" + (activeFilterIndex === index ? ' active-filter' : '')}
              key={index}
              onClick={() => { dispatch(setAFIServerLoadChart(index)); }}>
              {filter.label}
            </button>
          ))
        }
      </div>

      {
        status === 'success' &&
        <div>
          <div className='widget-list-container'>
            <Chart
              data={itemsState}
              props={
                {
                  lineDataKey: "count",
                  xAxisDataKey: "time",
                  CustomTooltip: CustomTooltip
                }
              }
            />
          </div>
        </div>
      }

      {
        status === 'loading' &&
        <p className='description small-font-size full-width justify-center align-center text-align-center'>Загрузка...</p>
      }
    </div>
  )
}

export default ServerLoadChart
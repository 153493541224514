import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Menu from '../Menu/Menu';
import './Layout.css';
import logo from '../../logo-large.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllPages, fetchPages, setLastPage } from './layout.slice';
import Signin from '../Signin/Signin';
import { fetchUser } from '../User/user.slice';
import upIcon from '../../images/up-bold.png';
import ImageComponent from '../ImageComponent/ImageComponent';
import { fetchShops } from '../Shop/shop.slice';


function Layout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token === null) {
            navigate('/login');
        }
    }, [navigate, token]);

    useEffect(() => {
        if (token) {
            dispatch(fetchPages(token + '?justPages=true'));
            dispatch(fetchAllPages(token + '?justPages=true&fullList=true'));
            dispatch(fetchShops(token));
            try {
                dispatch(fetchUser(token + '?item=' + token));
            } catch (error) {
                console.log(error);
            }
        }
    }, [dispatch, token]);

    const { pages, status } = useSelector((state => state.pages));

    useEffect(() => {
        if (status === 'success') {
            dispatch(setLastPage(location.pathname));
        }
    }, [navigate, status, pages]);

    const [scrollUpShowed, setScrollUpShowed] = useState(false);
    const mainElement = document.getElementsByTagName('main')[0];

    useEffect(() => {
        const scrollCheck = () => {
            const scrollTopPosition = mainElement.scrollTop;
            const userHeightOffset = mainElement.offsetHeight;

            if (scrollTopPosition > userHeightOffset + 100) {
                setScrollUpShowed(true);
            } else {
                setScrollUpShowed(false);
            }
        };
        mainElement !== undefined && mainElement.addEventListener('scroll', scrollCheck);
        return () => {
            mainElement !== undefined && mainElement.removeEventListener('scroll', scrollCheck);
        };
    }, [mainElement]);

    return (
        <>
            {
                location.pathname === '/login' &&
                <Signin />
            }

            {
                location.pathname !== '/login' &&
                <div>
                    {
                        status === 'success' &&
                        <div className='Layout-container'>
                            <aside className='aside'>
                                <Menu isLeftMenu={true} items={pages.filter(page => page?.isFolder !== true)} />
                            </aside>
                            <main className='main'>
                                <Outlet />
                                {
                                    scrollUpShowed === true &&
                                    <div className='scrollUpButtonContainer' onClick={() => { mainElement.scrollTo({ top: 0, behavior: 'smooth' }); setTimeout(() => mainElement.scrollTo(0, 0), 1000) }}>
                                        <ImageComponent src={upIcon} />
                                    </div>
                                }
                            </main>
                            <footer>
                                <Menu isLeftMenu={false} items={pages.filter(page => page?.isFolder !== true)} />
                            </footer>
                        </div>
                    }
                    {
                        status === 'loading' &&
                        <div className='preload-container'>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 'bold' }}>
                                <img src={logo} alt="" height={100} />
                            </div>
                        </div>
                    }
                    {
                        status === 'error' &&
                        <div className='error' style={{ padding: '15px' }}>
                            Произошла ошибка при загрузке страницы, попробуйте обновить страницу если проблема повторится попробуйте зайти позже
                        </div>
                    }
                </div>
            }

        </>
    )
}

export default Layout